// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

.wrapper {
  min-height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: 81px;
}
@media (max-width: 1024px) {
  .content {
    margin-top: 66px;
  }
}
@media (max-width: 991px) {
  .content {
    margin-top: 60px;
  }
}
@media (max-width: 460px) {
  .content {
    margin-top: 46px;
  }
}

.container {
  max-width: 1300px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

img, svg {
  vertical-align: middle;
}

.carousel {
  margin-bottom: 45px;
}

.carousel-image {
  position: relative;
  width: 100%;
}

.overlay-error {
  display: none; /* Скрываем overlay */
  /* Или */
  opacity: 0; /* Делаем overlay прозрачным */
}`, "",{"version":3,"sources":["webpack://./src/app.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,qCAAA;EACA,sBAAA;AAEJ;;AAGA;EACI,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ;AACI;EAFJ;IAGQ,gBAAA;EAEN;AACF;AADI;EALJ;IAMQ,gBAAA;EAIN;AACF;AAHI;EARJ;IASQ,gBAAA;EAMN;AACF;;AAHA;EACI,iBAAA;EACA,cAAA;AAMJ;;AAHA;EACI,qBAAA;AAMJ;;AAFA;EACI,sBAAA;AAKJ;;AAFA;EACI,mBAAA;AAKJ;;AAFA;EACI,kBAAA;EAEA,WAAA;AAIJ;;AADA;EACI,aAAA,EAAA,qBAAA;EACA,QAAA;EACA,UAAA,EAAA,8BAAA;AAIJ","sourcesContent":["* {\n    font-family: \"Montserrat\", sans-serif;\n    box-sizing: border-box;\n    \n}\n\n\n.wrapper {\n    min-height: 100%;\n    position: relative;\n    margin: 0;\n    padding: 0;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n}\n\n.content {\n    margin-top: 81px;\n    @media(max-width: 1024px) {\n        margin-top: 66px;\n    }\n    @media(max-width: 991px) {\n        margin-top: 60px;\n    }\n    @media(max-width: 460px) {\n        margin-top: 46px;\n    }\n}\n\n.container {\n    max-width: 1300px;\n    margin: 0 auto;\n}\n\na {\n    text-decoration: none;\n    \n}\n\nimg, svg {\n    vertical-align: middle;\n}\n\n.carousel {\n    margin-bottom: 45px;\n}\n\n.carousel-image {\n    position: relative;\n\n    width: 100%;   \n}\n\n.overlay-error {\n    display: none; /* Скрываем overlay */\n    /* Или */\n    opacity: 0; /* Делаем overlay прозрачным */\n  }\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
