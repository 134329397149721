// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cellshape {
  margin-top: 15px;
  border-bottom: 2px solid #e5e5e5;
}
.cellshape__title {
  font-size: 21px;
  font-weight: 600;
  color: #03142E;
  font-family: "Open Sans", sans-serif;
}
@media (max-width: 460px) {
  .cellshape__title {
    font-size: 19px;
  }
}
.cellshape__image {
  margin-top: 15px;
}
.cellshape__image img {
  width: 70px;
  height: 70px;
  border: 2px solid transparent;
  border-radius: 50%;
}
@media (max-width: 460px) {
  .cellshape__image img {
    width: 60px;
    height: 60px;
  }
}
.cellshape__subtitle {
  margin-top: 5px;
  font-size: 17px;
  font-weight: 500;
  color: #03142E;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  padding-left: 13px;
  padding-bottom: 10px;
}
@media (max-width: 460px) {
  .cellshape__subtitle {
    font-size: 15px;
    padding-left: 9px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Product/Cellshape/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gCAAA;AACJ;AAAI;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,oCAAA;AAER;AADQ;EALJ;IAMQ,eAAA;EAIV;AACF;AADI;EACI,gBAAA;AAGR;AADQ;EACI,WAAA;EACA,YAAA;EACA,6BAAA;EACA,kBAAA;AAGZ;AAFY;EALJ;IAMQ,WAAA;IACA,YAAA;EAKd;AACF;AAAI;EACI,eAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,oCAAA;EACA,yBAAA;EACA,kBAAA;EACA,oBAAA;AAER;AADQ;EATJ;IAUQ,eAAA;IACA,iBAAA;EAIV;AACF","sourcesContent":[".cellshape {\n    margin-top: 15px;\n    border-bottom: 2px solid #e5e5e5;\n    &__title {\n        font-size: 21px;\n        font-weight: 600;\n        color:#03142E;\n        font-family: 'Open Sans', sans-serif;  \n        @media(max-width: 460px) {\n            font-size: 19px;\n        }    \n    }\n\n    &__image {\n        margin-top: 15px;\n        \n        img {\n            width: 70px;\n            height: 70px;\n            border: 2px solid transparent;\n            border-radius: 50%;\n            @media(max-width: 460px) {\n                width: 60px;\n                height: 60px;\n            }\n          \n        }\n    }\n\n    &__subtitle {\n        margin-top: 5px;\n        font-size: 17px;\n        font-weight: 500;\n        color:#03142E;\n        font-family: 'Open Sans', sans-serif;  \n        text-transform: uppercase;\n        padding-left: 13px;\n        padding-bottom: 10px;\n        @media(max-width: 460px) {\n            font-size: 15px;\n            padding-left: 9px;\n        }\n\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
