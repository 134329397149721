// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.benefits {
  background-color: #03142E;
  padding-bottom: 50px;
  padding-top: 50px;
}
.benefits__title {
  text-align: left;
  color: #e5e5e5;
  font-family: "Open Sans", sans-serif;
  font-size: 33px;
  font-weight: 700;
  line-height: 1.3em;
}
@media (max-width: 1024px) {
  .benefits__title {
    font-size: 28px;
  }
}
@media (max-width: 768px) {
  .benefits__title {
    font-size: 23px;
  }
}
@media (max-width: 480px) {
  .benefits__title {
    font-size: 22px;
    font-weight: 600;
  }
}
.benefits-right {
  display: flex;
  justify-content: right;
}
@media (max-width: 460px) {
  .benefits-right {
    justify-content: left;
  }
}
.benefits__item {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.benefits__text {
  color: #e5e5e5;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  width: 650px;
  margin-left: 15px;
}
@media (max-width: 768px) {
  .benefits__text {
    width: 400px;
  }
}
@media (max-width: 595px) {
  .benefits__text {
    font-size: 16px;
  }
}
@media (max-width: 460px) {
  .benefits__text {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Benefits/style.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,oBAAA;EACA,iBAAA;AACJ;AAEI;EACI,gBAAA;EACA,cAAA;EACA,oCAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AAAR;AACQ;EAPJ;IAQQ,eAAA;EAEV;AACF;AADQ;EAVJ;IAWQ,eAAA;EAIV;AACF;AAHQ;EAbJ;IAcQ,eAAA;IACA,gBAAA;EAMV;AACF;AADI;EACI,aAAA;EACA,sBAAA;AAGR;AAFQ;EAHJ;IAIQ,qBAAA;EAKV;AACF;AAFI;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;AAIR;AADI;EACI,cAAA;EACA,oCAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AAGR;AAFQ;EAPJ;IAQQ,YAAA;EAKV;AACF;AAJQ;EAVJ;IAWQ,eAAA;EAOV;AACF;AANQ;EAbJ;IAcQ,WAAA;EASV;AACF","sourcesContent":[".benefits {\n    background-color: #03142E;\n    padding-bottom: 50px;\n    padding-top: 50px;\n\n\n    &__title {\n        text-align: left;\n        color: #e5e5e5;\n        font-family: 'Open Sans', sans-serif;\n        font-size: 33px;\n        font-weight: 700;\n        line-height: 1.3em;\n        @media(max-width: 1024px) {\n            font-size: 28px;\n        }\n        @media(max-width: 768px) {\n            font-size: 23px;\n        }\n        @media(max-width: 480px) {\n            font-size: 22px;\n            font-weight: 600;\n        }\n    }\n\n    \n\n    &-right {\n        display: flex;\n        justify-content: right;\n        @media(max-width: 460px) {\n            justify-content: left;\n        }\n    }\n\n    &__item {\n        display: flex;\n        align-items: center;\n        margin-top: 15px;\n    }\n\n    &__text {\n        color: #e5e5e5;\n        font-family: 'Open Sans', sans-serif;\n        font-size: 18px;\n        font-weight: 500;\n        width: 650px;\n        margin-left: 15px;\n        @media(max-width: 768px) {\n            width: 400px;\n        }\n        @media(max-width: 595px) {\n            font-size: 16px;\n        }\n        @media(max-width: 460px) {\n            width: 100%;\n        }\n        \n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
