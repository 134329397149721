// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.goods {
  margin-top: 15px;
}
.goods__search {
  margin-top: 14px;
  width: 450px;
  height: 40px;
  padding: 5px;
  border: 2px solid #b5b8b8;
  border-radius: 10px;
}

.form-select, .form-control {
  width: 450px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/AdminCreateOrder/Goods/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACI;EACI,gBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;AACR;;AAGA;EACI,uBAAA;AAAJ","sourcesContent":[".goods {\n    margin-top: 15px;\n\n    &__search {\n        margin-top: 14px;\n        width: 450px;\n        height: 40px;\n        padding: 5px;\n        border: 2px solid #b5b8b8;\n        border-radius: 10px;\n    }\n}\n\n.form-select, .form-control {\n    width: 450px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
