// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.materials {
  margin-top: 15px;
  border-bottom: 2px solid #e5e5e5;
}
.materials__title {
  font-size: 21px;
  font-weight: 600;
  color: #03142E;
  font-family: "Open Sans", sans-serif;
  width: 340px;
}
@media (max-width: 460px) {
  .materials__title {
    font-size: 19px;
  }
}
.materials__content {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
}
.materials__content-item {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: 2px solid #e5e5e5;
  cursor: pointer;
  margin-right: 5px;
}
.materials__content-item:hover {
  border: 2px solid #ff0000;
}
@media (max-width: 991px) {
  .materials__content-item:hover {
    border: 2px solid #e5e5e5;
  }
}
.materials__content-item.active {
  border-color: #ff0000;
}
@media (max-width: 595px) {
  .materials__content-item {
    padding-right: 7px;
  }
}
@media (max-width: 385px) {
  .materials__content-item {
    width: 40px;
    height: 40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Product/Materials/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gCAAA;AACJ;AAAI;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,oCAAA;EACA,YAAA;AAER;AADQ;EANJ;IAOQ,eAAA;EAIV;AACF;AADI;EACI,iBAAA;EACA,oBAAA;EACA,aAAA;AAGR;AAFQ;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,eAAA;EACA,iBAAA;AAIZ;AAHY;EACI,yBAAA;AAKhB;AAJgB;EAFJ;IAGQ,yBAAA;EAOlB;AACF;AALY;EACI,qBAAA;AAOhB;AALY;EAhBJ;IAiBQ,kBAAA;EAQd;AACF;AAPY;EAnBJ;IAoBQ,WAAA;IACA,YAAA;EAUd;AACF","sourcesContent":[".materials {\n    margin-top: 15px;\n    border-bottom: 2px solid #e5e5e5;\n    &__title {\n        font-size: 21px;\n        font-weight: 600;\n        color:#03142E;\n        font-family: 'Open Sans', sans-serif;   \n        width: 340px; \n        @media(max-width: 460px) {\n            font-size: 19px;\n        }\n    }\n\n    &__content {\n        padding-top: 15px;\n        padding-bottom: 15px;\n        display: flex;\n        &-item {\n            width: 30px;\n            height: 30px;\n            border-radius: 50px;\n            border: 2px solid #e5e5e5;\n            cursor: pointer;\n            margin-right: 5px;\n            &:hover {\n                border: 2px solid #ff0000;\n                @media(max-width: 991px) {\n                    border: 2px solid #e5e5e5; \n                }\n            }\n            &.active {\n                border-color: #ff0000;\n            }\n            @media(max-width: 595px) {\n                padding-right: 7px;\n            }\n            @media(max-width: 385px) {\n                width: 40px;\n                height: 40px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
