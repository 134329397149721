// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.burger {
  display: none;
}
@media (max-width: 769px) {
  .burger {
    display: block;
    margin-top: 13px;
  }
}
@media (max-width: 595px) {
  .burger {
    margin-top: 8px;
  }
}
@media (max-width: 480px) {
  .burger {
    margin-top: 9px;
  }
}
.burger__border-one {
  width: 35px;
  height: 3px;
  background-color: black;
  margin: 4px 0;
}
@media (max-width: 595px) {
  .burger__border-one {
    height: 2px;
    margin: 8px 0px;
  }
}
@media (max-width: 480px) {
  .burger__border-one {
    width: 25px;
    margin: 7px 0px;
  }
}
@media (max-width: 460px) {
  .burger__border-one {
    height: 1px;
  }
}
.burger__border-three {
  width: 26px;
  height: 3px;
  background-color: black;
  margin: 4px 0;
}
@media (max-width: 595px) {
  .burger__border-three {
    height: 2px;
    margin: 8px 0px;
  }
}
@media (max-width: 480px) {
  .burger__border-three {
    width: 20px;
    margin: 7px 0px;
  }
}
@media (max-width: 460px) {
  .burger__border-three {
    height: 1px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Burger/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EAFJ;IAGQ,cAAA;IACA,gBAAA;EAGN;AACF;AAFI;EANJ;IAOQ,eAAA;EAKN;AACF;AAJI;EATJ;IAUQ,eAAA;EAON;AACF;AAJI;EACI,WAAA;EACA,WAAA;EACA,uBAAA;EACA,aAAA;AAMR;AALQ;EALJ;IAMQ,WAAA;IACA,eAAA;EAQV;AACF;AAPQ;EATJ;IAUQ,WAAA;IACA,eAAA;EAUV;AACF;AARQ;EAdJ;IAeQ,WAAA;EAWV;AACF;AAPI;EACI,WAAA;EACA,WAAA;EACA,uBAAA;EACA,aAAA;AASR;AARQ;EALJ;IAMQ,WAAA;IACA,eAAA;EAWV;AACF;AAVQ;EATJ;IAUQ,WAAA;IACA,eAAA;EAaV;AACF;AAXQ;EAdJ;IAeQ,WAAA;EAcV;AACF","sourcesContent":[".burger {\n    display: none;\n    @media(max-width: 769px) {\n        display: block;\n        margin-top: 13px;\n    }\n    @media(max-width: 595px) {\n        margin-top: 8px;\n    }\n    @media(max-width: 480px) {\n        margin-top: 9px;\n    }\n   \n\n    &__border-one {\n        width: 35px;\n        height: 3px;\n        background-color: black;\n        margin: 4px 0;\n        @media(max-width: 595px) {\n            height: 2px;\n            margin: 8px 0px;\n        }\n        @media(max-width: 480px) {\n            width: 25px;\n            margin: 7px 0px;\n            \n        }\n        @media(max-width: 460px) {\n            height: 1px;\n        }\n    }\n\n\n    &__border-three {\n        width: 26px;\n        height: 3px;\n        background-color: black;\n        margin: 4px 0;\n        @media(max-width: 595px) {\n            height: 2px;\n            margin: 8px 0px;\n        }\n        @media(max-width: 480px) {\n            width: 20px;\n            margin: 7px 0px;\n            \n        }\n        @media(max-width: 460px) {\n            height: 1px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
