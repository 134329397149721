// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-order__title {
  font-size: 22px;
  font-weight: 500;
  color: var(--e-global-color-primary);
}
.create-order__steps {
  display: contents;
}
.create-order__steps-title {
  font-size: 20px;
  font-weight: 400;
  color: var(--e-global-color-primary);
}
.create-order__step {
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/AdminCreateOrder/style.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,gBAAA;EACA,oCAAA;AAAR;AAGI;EAEI,iBAAA;AAFR;AAIQ;EACI,eAAA;EACA,gBAAA;EACA,oCAAA;AAFZ;AAMI;EACI,gBAAA;AAJR","sourcesContent":[".create-order {\n    &__title {\n        font-size: 22px;\n        font-weight: 500;\n        color: var(--e-global-color-primary);\n    }\n\n    &__steps {\n   \n        display: contents;\n\n        &-title {\n            font-size: 20px;\n            font-weight: 400;\n            color: var(--e-global-color-primary);\n        }\n    }\n\n    &__step {\n        margin-top: 15px;\n    }\n\n   \n\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
